<script setup>

import Form from "~/components/atoms/Form.vue";
import FormInput from "~/components/atoms/FormInput.vue";

const name = ref('');
const email = ref('');
</script>

<template>
  <Form>
    <template #default>
      <FormInput :validations="['required']" v-model="name" :label="$t('catalogs.form.name')" name="name"/>

      <FormInput :validations="['required','email']" v-model="email" type="email" :label="$t('catalogs.form.email')" name="email"/>
    </template>

    <template #submit>{{ $t('catalogs.form.send') }}</template>
  </Form>
</template>

<style scoped lang="scss">

</style>
